import { Decimal } from 'decimal.js';
import * as web3utils from 'web3-utils';
import validate from './validate';

Decimal.set({ toExpNeg: -30, toExpPos: 30 });

export function sub(v1, v2) {
  return Decimal.sub(v1, v2).valueOf();
}

export function plus(v1, v2) {
  return Decimal.add(v1, v2).valueOf();
}

export function ADD(v1,v2){
    v1 = v1 || 0
    v2 = v2 || 0
    return Decimal.add(v1, v2).valueOf();
}

export function mul(v1, v2) {
  return Decimal.mul(v1, v2).valueOf();
}

export function div(v1, v2) {
  return Decimal.div(v1, v2).valueOf();
}

export function EQ(val, compareVal) {
  return Decimal(val).eq(compareVal);
}

export function GT(val, compareVal) {
  return Decimal(val).gt(compareVal);
}

export function GE(val, compareVal) {
  return Decimal(val).gte(compareVal);
}

export function LT(val, compareVal) {

    if(!window.validate.isNumber(val) || !window.validate.isNumber(compareVal)){
        return false;
    }
    return Decimal(val).lt(compareVal);
}

export function LE(val, compareVal) {
  return Decimal(val).lte(compareVal);
}

export function pow(x,y){
    return Decimal.pow(x,y).valueOf();
}

export function ceil(num){
    return Decimal.ceil(num).valueOf();
}

export function floor(num){
    return Decimal.floor(num).valueOf();
}

export function fromWei(num, decimal = 18) {
    num = num+"";
    return Number(decimal)===18 ? web3utils.fromWei(num) : div(num, Math.pow(10, decimal)).valueOf();
}

export function toWei(num, decimal = 18,float="floor") {
    num = num || 0;
    num = num+"";
    num = toFixed(num,decimal,float);
    return Number(decimal)===18 ? web3utils.toWei(num) : mul(num, Math.pow(10, decimal)).valueOf();
}

export function toNumber(val) {
    return Decimal(val).toNumber();
}

export function toExponential(value){

    if(value==='0' || !value) return 0;

    const Dec = Decimal(value);

    if(value >= 1){
        if( Dec.isInteger() ){
            return Dec.toString()
        }
        return parseFloat( Dec.toFixed(6, Decimal.ROUND_DOWN) );
    }

    if(Dec.e < -3){
        return Dec.toExponential(6, Decimal.ROUND_DOWN);
    }

    return parseFloat( Dec.toFixed(6, Decimal.ROUND_DOWN) );
}

export function toFixedString(number,len){
    if(!number) return number;

    const arr = number.toString().split(".");

    arr[0] = parseInt(arr[0]);

    if(arr.length === 1) return arr[0];

    if(arr[1].length > len){
        arr[1] = arr[1].substring(0,len);
    }
    return arr.join(".");
}

export function toFixed(num, dec = 18,type='floor') {

    if(!num){return num}
    const p = pow(10,dec);
    const l = mul(num,p);
    const c = type==='floor' ? floor(l) : ceil(l);
    const m = div(c,p);
    return m;
}

export function toFmtThousand(num){
    if(!validate.isNumber(num,"float")){
      return num;
    }
      let result = num.toString().replace(/\d+/, function(n){
          return n.replace(/(\d)(?=(\d{3})+$)/g,function($1){
             return $1+",";
          });
     })
     return result;
}


////////////////////////////////////////

export function schedule(val){
  const schedule_now = parseFloat(val.f_token_amount_now) / parseFloat(val.f_token_amount) * 100
  return parseFloat( schedule_now.toFixed(2) )
}

export function schedule2(v1,v2){
  const schedule_now = parseFloat(v2) / parseFloat(v1) * 100
  return toFixed(schedule_now,2)
}

export function divTime(time1,time2,time3){
    if(parseInt(time2)< parseInt(time1)){
      return 0
    }
    return  ((time2 - time1) * time3 / 3600) ;
}


