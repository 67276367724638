import { ApiGetpointList } from "@/service/interface/publicAction"
import { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import * as Tool from '@/utils/Tools';
const BotPoint = () => {
    const {wallet} = useSelector(state => state)
    const [point, setPoint] = useState(0)
    useEffect(() => {
        getPoint()
    },[wallet])
    const getPoint = async() => {
        ApiGetpointList({
            chainId: wallet.chainId,
            walletaddr: wallet.account
        }).then(res => {
            console.log('res :>> ', res);
            setPoint(res.myrank?.signpoint)
        })
    }
    return(
        <div className="bot_point">
            My Bot Points 
            <span className="point a_c_3">{Tool.toFmtThousand(point || '--')}</span>
        </div>
    )
}

export default BotPoint