
import React,{useState,useRef, useEffect} from 'react';
import { Link ,useHistory,useLocation } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import './header.scss';
import { useSelector } from 'react-redux';
import { DropBox } from '../../../components/ui';

const HeaderNav = ({handleClick})=>{
    const location = useLocation();
    const { rect } = useSelector(state => state)
    const navConfig = [
        {
            title: 'Project',
            router: '/',
            type: '1',
            link: ''
        },
        {
            title: 'Claim Rewards',
            router: '/receive/ETH',
            type: '1',
            link: ''
        },
        {
            title: 'Leaderboard',
            router: '/leaderboard',
            type: '1',
            link: ''
        },
        {
            title: 'Verification',
            router: 'https://forms.gle/Q3GfQNRG38ztDXE28',
            type: '2',
            link: 'https://forms.gle/Q3GfQNRG38ztDXE28'
        },
    ]
    const [menu, setMenu] = useState(navConfig)
    const [tempMenu, setTempMenu] = useState([])
    const history = useHistory()
    const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth);

    useEffect(() => {
        const handleResize = () => {
            let width = document.documentElement.clientWidth
            let menu2 = JSON.parse(JSON.stringify(navConfig))
            let tempMenu = []
            if (width < 768) {
                setMenu(menu2)
                setTempMenu([])
                return
            }
            if (width < 930) {
                let arr = menu2.splice(-1)
                tempMenu.unshift(arr)
                setMenu(menu2)
            }
            if (width < 1100) {
                let arr = menu2.splice(-2)
                tempMenu.unshift(arr)
                setMenu(menu2)
            }else {
                setMenu(navConfig)
            }

            let t = tempMenu.flat()
            setTempMenu(t)
        };
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])
    return (
        <div className="menuList" onClick={handleClick}>
            {
                menu.map((val,index) => 
                    <span key={index} className={[val.router].includes(location.pathname) ? 'active list_span' : 'list_span'} onClick={() => {
                        if(val.type === '1'){
                            history.push(val.router)
                        }else{
                            window.open(val.link)
                        }
                    }}>
                        <span >{val.title}</span>
                    </span>
                )
            }
            {
                menu.length <= 3 && <div className={`list_span`}>
                    <DropBox
                    animation={true}
                    title={<div className='title fw_600 pr_4 pl_4'><span className='picon p-icon-More2 is_4' /></div>}
                    >
                        
                        {
                            tempMenu?.map((val, ind) => 
                                <div className={`pa_5 fs_2 bd_b ${val.router === location.pathname && 'text_1'}`} key={ind}>
                                    {
                                        val.type === '1' && <span onClick={() => {history.push(val.router)}}>{val.title}</span>
                                    }
                                    {
                                        val.type === '2' && <span onClick={() => {window.open(val.link)}}>{val.title}</span>
                                    }
                                </div>
                            )   
                        }
                    </DropBox>
                </div>
            }
            {/* <span className={['/'].includes(location.pathname) ? 'active list_span' : 'list_span'}>
                <Link to="/">Project</Link>
            </span>
            <span className={location.pathname.indexOf('/receive')>=0 ? 'active list_span' : 'list_span'}>
                <Link to="/receive/ETH">Claim Rewards</Link>
            </span>
            <span className={location.pathname.indexOf('/leaderboard')>=0 ? 'active list_span' : 'list_span'}>
                <Link to="/leaderboard">Leaderboard</Link>
            </span>
            <span className='list_span'>
                <a href='https://forms.gle/Q3GfQNRG38ztDXE28' target="_blank" rel="noreferrer">Verification</a>
            </span> */}
            {/* <span className={location.pathname.indexOf('/account')>=0 ? 'active list_span' : 'list_span'}>
                <Link to="/">项目申请</Link>
            </span> */}
        </div>
    )
}
export default HeaderNav;

