import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Spin } from 'antd';

import CommonHeader from '../views/commonModule/header';
import WalletConnect from '../views/commonModule/wallet';

export function slowImport(value, ms = 1000) {
    return new Promise((resolve) => {
        setTimeout(() => resolve(value), ms);
    });
}


const Progress = React.lazy(()=>import('../views/components/progress'));
const Receive = React.lazy(()=>import('../views/components/receive'))
const Detail = React.lazy(()=>import('../views/components/detail'))
const LeaderBoard = React.lazy(()=>import('../views/leaderBoard'))
const AppRouter = () => {
    return (
        <Router>
            <CommonHeader></CommonHeader>
            <Suspense
                fallback={
                    <div className="global_loading">
                        <Spin tip="Loading..." />
                    </div>
                }>
                <div className="page">
                    <Switch>
                        <Route path="/" exact component={Progress} />
                        <Route path="/receive/:type" exact component={Receive}/>
                        <Route path="/detail" exact component={Detail}/>
                        <Route path="/leaderboard" exact component={LeaderBoard} />
                        <Redirect exact to="/" />
                    </Switch>
                </div>
            </Suspense>
            {/* <CommonFooter></CommonFooter> */}
            <WalletConnect />
        </Router>
    );
};

export default AppRouter;
