import types from '../types'

const defaultState = {
    typeDialog: false,
    platformDialog: false,
    addressDialog: false,
    typeInfo: {},
    platformInfo: {},
    guarantyAddress: "",
    guarantyId: ""
};

const pawn = (state = defaultState, action) => {
    switch (action.type) {
        case types.Pawn_Modal:
            return {
                ...state,
                ...action.info
            }
        default:
            return state
    }
};

export default pawn;
