import './index.scss'

export function DropBox(props){
    const position = props.position || 'bottom'
    return (
        <div className='dropMenuModule'>
            {props.title}
            {
                props.children &&
                <div className={`dropMenu ${position}`}>
                    <div className="dropMenuList b_g_3_1 shadow_1">
                        {props.children}
                    </div>
                </div>
            }
        </div>
    )
}

export default DropBox;

