import axios from 'axios';
import qs from 'qs';
import { ChainConfig } from '@/config'
import { Spin ,message} from 'antd';
import  handleData from './dataHanding'


const interceptors = (instance,params) => {
    // request 拦截器
    instance.interceptors.request.use(
        (config) => config,
        (error) => Promise.reject(error)
    );
    // response 拦截器
    instance.interceptors.response.use(response => {

        if (process.env.NODE_ENV === 'development') console.info(`接口${response.config.url}响应信息:`, response);
        message.destroy();
        const { code, msg } = response.data;

        // 如果是object , onlyShowTokenSymbol,

        if (!code || code === '0') {
            return Promise.resolve( response.data.info || '');
        } else {
            return Promise.reject(Object.assign({}, { msg: code || msg, code }));
        }

    },error => {

        try {
            if (process.env.NODE_ENV === 'development') console.error(`接口${error.response.config.url}响应错误信息:`, error.response);
            const code = error.response.status || '';
            const msg = error.response.data.msg;
            return Promise.reject(Object.assign({}, { code, msg: code || msg || 'system andling exceptions.' }));
        } catch (e) {
            // return Promise.reject(Object.assign({}, { msg: i18next.t('-1'), code: -1 }));
            return Promise.reject(Object.assign({}, { msg: '-1', code: -1 }));
        }

    });
};

/**
 * @param {*} url
 * @param {*} params
 */
export function query(url, params, type='get') {
    const commonConfig = new ChainConfig(params.chainId)
    
    const apiUrl = commonConfig.apiAddress + url;
    console.log(apiUrl)

    const instance = axios.create({ timeout: 10000, headers: { 'Content-Type': 'application/x-www-form-urlencoded', Accept: 'application/json' } });
    interceptors(instance,params);

    let paramsTemp = {};
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const element = params[key];
            paramsTemp[key] = element;
        }
    }

   // message.success(<Spin className="waiting" tip="Loading..."></Spin>, [1000])
    const hide= message.success({
        content:<Spin className="waiting" tip="Loading..."></Spin>,
        className:'waiting',
        duration:100
    })
    hide()
    paramsTemp = { p: window.JSON.stringify(paramsTemp) };

    if(type === 'get'){
        return instance.get(apiUrl + '?' + qs.stringify(paramsTemp));
    }else{
        console.log(qs.stringify(paramsTemp))
        return instance.get(apiUrl, qs.stringify(paramsTemp));
    }
}


/**
 * 多个请求合并
 * @param {*} reqs
 *
 */
export function ALL(reqs = []) {
    return Promise.all(reqs);
}




