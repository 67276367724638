/* eslint-disable import/no-anonymous-default-export */

// chainId 仓库
export default {
    1 : {
        type: "ETH",
        env: "production",
        chainId: 1,
        mainSymbol: "ETH",
        networkName: "Main ETH",
        networkAlias: "mainnet",
        rpcUrl: 'https://mainnet.infura.io/v3/34c59a94d8ef460086c55841531e0b67',
        blockTime: 15000,
        explorerName: 'Etherscan',
        explorerUrl: 'https://etherscan.io/'
    },
    3 : {
        type: "ETH",
        env: "test",
        chainId: 3,
        mainSymbol: "ETH",
        networkName: "Ropsten",
        networkAlias: "ropsten",
        rpcUrl: 'https://ropsten.infura.io/v3/34c59a94d8ef460086c55841531e0b67',
        blockTime: 15000,
        explorerName: 'Etherscan',
        explorerUrl: 'https://ropsten.etherscan.io/'
    },
    4 : {
        type: "ETH",
        env: "test",
        chainId: 4,
        mainSymbol: "ETH",
        networkName: "Rinkeby",
        networkAlias: "rinkeby",
        rpcUrl: 'https://rinkeby.infura.io/v3/34c59a94d8ef460086c55841531e0b67',
        blockTime: 15000,
        explorerName: 'Etherscan',
        explorerUrl: 'https://rinkeby.etherscan.io/'
    },
    5 : {
        type: "ETH",
        env: "test",
        chainId: 5,
        mainSymbol: "ETH",
        networkName: "Goerli",
        networkAlias: "goerli",
        rpcUrl: 'https://goerli.infura.io/v3/34c59a94d8ef460086c55841531e0b67',
        blockTime: 15000,
        explorerName: 'Etherscan',
        explorerUrl: 'https://goerli.etherscan.io/'
    },
    42 : {
        type: "ETH",
        env: "test",
        chainId: 42,
        mainSymbol: "ETH",
        networkName: "Kovan",
        networkAlias: "kovan",
        rpcUrl: 'https://kovan.infura.io/v3/34c59a94d8ef460086c55841531e0b67',
        blockTime: 15000,
        explorerName: 'Etherscan',
        explorerUrl: 'https://kovan.etherscan.io/'
    },
    56 : {
        type: "BSC",
        env: "production",
        chainId: 56,
        mainSymbol: "BNB",
        networkName: "Mainnet BSC",
        networkAlias: "bscmain",
        rpcUrl: 'https://bsc-dataseed1.binance.org/',
        blockTime: 3000,
        explorerName: 'Bscscan',
        explorerUrl: 'https://bscscan.com/'
    },
    97 : {
        type: "BSC",
        env: "test",
        chainId: 97,
        mainSymbol: "BNB",
        networkName: "Testnet BSC",
        networkAlias: "bsctest",
        rpcUrl: 'https://testnet.bscscan.com/',
        blockTime: 3000,
        explorerName: 'Bscscan',
        explorerUrl: 'https://testnet.bscscan.com/'
    },
    128 : {
        type: "HECO",
        env: "production",
        chainId: 128,
        mainSymbol: "HT",
        networkName: "Mainnet HECO",
        networkAlias: "hecomain",
        rpcUrl: 'https://http-mainnet-node.huobichain.com/',
        blockTime: 3000,
        explorerName: 'hecochain',
        explorerUrl: 'https://hecoinfo.com/'
    },
    256 : {
        type: "HECO",
        env: "test",
        chainId: 256,
        mainSymbol: "HT",
        networkName: "Testnet HECO",
        networkAlias: "hecotest",
        rpcUrl: 'https://http-testnet.hecochain.com/',
        blockTime: 3000,
        explorerName: 'hecochain',
        explorerUrl: 'https://testnet.hecoinfo.com/'
    },
    66 : {
        type: "Gate",
        env: "production",
        chainId: 66,
        mainSymbol: "Gate",
        networkName: "Mainnet Gate",
        networkAlias: "gatemain",
        rpcUrl: 'https://evm.gatenode.cc',
        blockTime: 3000,
        explorerName: 'gatechain',
        explorerUrl: 'https://testnet.hecoinfo.com/'
    },
    1337 : {
        type: "Gate",
        env: "test",
        chainId: 1337,
        mainSymbol: "Gate",
        networkName: "Testnet Gate",
        networkAlias: "gatetest",
        rpcUrl: 'http://meteora.gatenode.cc:6060',
        blockTime: 3000,
        explorerName: 'gatechain',
        explorerUrl: 'https://testnet.hecoinfo.com/'
    },
    65 : {
        type: "oKex",
        env: "production",
        chainId: 65,
        mainSymbol: "oKex",
        networkName: "Testnet oKex",
        networkAlias: "okexmain",
        rpcUrl: 'https://exchain.okexcn.com',
        blockTime: 3000,
        explorerName: 'okexchain',
        explorerUrl: 'https://testnet.hecoinfo.com/'
    },
    212984383488152 : {
        type: "Arbit",
        env: "production",
        chainId: 212984383488152,
        mainSymbol: "Arbit",
        networkName: "Testnet Arbit",
        networkAlias: "arbitest",
        rpcUrl: 'https://kovan4.arbitrum.io/rpc',
        blockTime: 3000,
        explorerName: 'arbitchain',
        explorerUrl: 'https://testnet.hecoinfo.com/'
    }
};
