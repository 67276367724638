import web3utils from 'web3-utils';


const validate = {

    /*
     * @describe check for valid contract address
     * @param String [addr]
     * @param Number [len]
     * return Boolean
     */
    isContractAddr(addr,len=42){
        if(!addr || addr.length!==42) return false;
        return web3utils.isAddress(addr);
    },

    /*
     * @describe check for valid numerical value
     * @param Number [n]
     * @param String [type]
     * return Boolean
     */
    isNumber(n,type='float'){
        if(n===undefined || n==='') return false;
        const regInt = /^[0-9]\d{0,}$/;
        const regFloat = /^([1-9]\d{0,18}|0)(\.\d{1,18})?$/;
        return type === 'float' ? regFloat.test(n) : regInt.test(n);
    },
    isUsableInput(n,dec=18){
        if(n===undefined || n==='') return false;
        const reg = new RegExp("^([1-9]\\d{0,18}|0)(\\.|\\.\\d{1,"+dec+"})?$");
        return reg.test(n);
    },

    /*
     * @describe check for valid images file
     * @param Number [n]
     * @param String [type]
     * return Boolean
     */
    isImage(minetype){
        if(minetype===undefined || minetype==='') return false;
        const regInt = /^[0-9]\d{0,}$/;
        const regFloat = /(^[0-9]\d{0,}$)|(^[0-9]\d{0,}\.[0-9]{1,}$)/;
        // return type === 'float' ? regFloat.test(n) : regInt.test(n);
    },

    /*
     * @describe * check for valid specified extension
     * @param String [filename]
     * @param Array [supportExt]
     * return Boolean/String
     */
    isSupportFileExt(filename,supportExt){
        if(!filename || filename.length < 2) return false;
        let arr = filename.split('.');
        if(!arr[1]) return false;
        let ext = arr[1].toLowerCase()
        if( !supportExt.includes(ext) ){
            return false;
        }
        return ext;
    }

}

export default validate;

