/* eslint-disable no-extend-native */
import exendMethods from './exendMethods';

const common = {

    /*
     * @describe Insert loading dialog in body
     * @param String [htmlStr]
     * return String
     */
    loading(htmlStr){
        if(htmlStr !== false){
            let mask = "<div class='loading'><div class='loader-07'></div></div>";
            document.getElementById("mask").innerHTML = mask;
            return;
        }
        document.getElementById("mask").innerHTML = '';
    },

    /*
     * @describe Reconstructing localStorage Methods
     * @param String [key]
     * @param String [value]
     * return String
     */
	storage(key,value){
        if(arguments.length===0){
            new Error('11111');
        }
        if(arguments.length===1){
            return localStorage.getItem(key);
        }
        if(typeof value==='string' && value.length===0){
            return localStorage.removeItem(key);
        }
        return localStorage.setItem(key, value);

    },

    /*
     * @describe Access "json" object" in localStorage
     * @param String [key]
     * @param String [value]
     * return String
     */
    storageJson(key,value){

        if(arguments.length===1){
            let info = JSON.parse(localStorage.getItem(key));
            return info || null;
        }

        if(typeof value === 'string' && value === ''){
            return localStorage.removeItem(key);
		}

        if(typeof value === 'object'){
            return localStorage.setItem(key,JSON.stringify(value));
        }
    },

    /*
     * @describe Reconstructing sessionStorage Methods
     * @param String [key]
     * @param String [value]
     * return String
     */
    session(key,value){
        if(arguments.length===1){
            return sessionStorage.getItem(key);
        }
        if(typeof value==='string' && value.length===0){
			return sessionStorage.removeItem(key);
		}
        return sessionStorage.setItem(key, value);

    },

    /*
     * @describe Access "json" object" in sessionStorage
     * @param String [key]
     * @param String [value]
     * return String
     */
    sessionJson(key,value){

        if(arguments.length===1){
            let info = JSON.parse(sessionStorage.getItem(key));
            return info || null;
        }

        if(typeof value === 'string' && value === ''){
            return sessionStorage.removeItem(key);
		}

        if(typeof value === 'object'){
            return sessionStorage.setItem(key,JSON.stringify(value));
        }
    },

    /*
     * @describe Generate 64-bit strings , add timestamp
     * @param Int [length]
     * return String
     * str="abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
     */
    randomUniqueString(length,prefix=''){

        let str="abcdef0123456789"
        let res = [];
        let ctime = new Date().getTime().toString();

        for(let i=0; i < length; i++ ) {
            if(res.length < ctime.length){
                res.push( str[ctime[i]] );
            }else{
                let bit = parseInt(Math.random() * 1000); console.log(bit);
                bit = bit >= 16 ? bit % 10 : bit;
                res.push( str[bit] );
            }
        }

        return prefix + res.join('');
    },

    /*
     * @describe get the state corresponding to the current url
     * @param Array [listArray]
     * return listArray[]{}value
     */
    getHistoryInfo(listArray){
        let index = "0";
        listArray.forEach((val,i)=>{
            let browser = window.location.pathname.toLowerCase();
            let current = val.link.toLowerCase();
            if(browser.indexOf(current)>=0){
                index = val.value;
            }
        })
        return index;
    }

}

export default common;




