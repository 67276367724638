import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import AppRouter from './routers';
import store from './store';
import { validate,common } from './utils';
// import './i18n';
import './styles/global.scss';

import reportWebVitals from './reportWebVitals';

window.validate = validate;
window.common = common;

const getLibrary = (provider) => {
    const library = new Web3Provider(provider);
    library.pollingInterval = 15000;
    return library;
};


console.log(window.common)

ReactDOM.render(
    <Web3ReactProvider getLibrary={getLibrary}>
        <Provider store={store}>
            <AppRouter />
        </Provider>
    </Web3ReactProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
