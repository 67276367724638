
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ETH: {
        test:[
            {
                lable: "ETH",
                name: "ETH",
                value: "ETH",
                contract_type: 'origin',
                decimals:18,
            },
            {
                lable: "USDT",
                name: "USDT",
                value: "USDT",
                contract_type: 'erc20',
                decimals:18,
            }
        ],
        production: [

        ],
    },
    BSC: {
        test:[
            {
                lable: "BNB",
                name: "BNB",
                value: "BNB",
                contract_type: 'origin',
                decimals:18,
            },
            {
                lable: "USDT",
                name: "USDT",
                value: "USDT",
                contract_type: 'erc20',
                decimals:18,
            }
        ],
        production: [

        ],
    },
    HECO: {
        test:[
            {
                lable: "HT",
                name: "HT",
                value: "HT",
                contract_type: 'origin',
                decimals:18,
            },
            {
                lable: "USDT",
                name: "USDT",
                value: "USDT",
                contract_type: 'erc20',
                decimals:18,
            },
            {
                lable: "USDTS",
                name: "USDTS",
                value: "USDTS",
                contract_type: 'erc20',
                decimals:6,
            }
        ],
        production: [

        ],
    }
}
