import React from 'react';
import './index.scss';

const ButtonBox = (props)=>{
    const {img,showImg} = props

    const click = () =>{
        if(props.disabled){
            return 0;
        }
        props.click && props.click(props);
    }

    const boxType = ()=>{
        const style = ['box'];
        if(props.gradient){
            style.push('gradient');
        }
        if(props.round){
            style.push('round')
        }
        if(props.middleWidth){
            style.push('middleWidth')
        }
        if(props.normal){
            style.push('normal')
        }
        if(props.circle){
            style.push('circle');
        }
        if(props.fill){
            style.push('fill');
        }
        if(props.disabled){
            style.push('disable');
        }
        if(props.className){
            style.push(props.className);
        }
        if(props.listButton){
            style.push('listButton');
        }
        if(props.twolistButton){
            style.push('twolistButton');
        }
        if(props.leftlistButton){
            style.push('leftlistButton');
        }
        if(props.quhui){
            style.push('quhui');
        }
        if(props.draw){
            style.push('draw');
        }
        if(props.draws){
            style.push('draws');
        }
        if(props.size){
            props.size==='small' && style.push('small')
            props.size==='middle' && style.push('middle')
            props.size==='large' && style.push('large')
        }
        return style.join(" ");
    }

    return (

        <div className="buttonBox">
            <div className={boxType()} onClick={()=>click()}>
                <div className="prev">
                    {/* img_wallet_white.png */}
                    {
                        showImg 
                        ?  <img src={`/images/credit/${img}`} style={{width:'30px',height:'30px'}}/>
                        : ''
                    }        
                </div>
                <div className="texts">
                    <span>{props.children}</span>
                </div>
                <div className='next'></div>
            </div>
        </div>
    )
}


export default ButtonBox;

