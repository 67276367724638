import types from '../types'

const userlogin = window.localStorage.getItem('userAuthorize') ? false : true;


const defaultState = {
    marketPawnDialog: false,
    marketSellDialog: false,
    marketRentDialog: false,

    myPawnRepayDialog: false,
    myPawnWithdrawDialog: false,
    myRentRepayDialog: false,

    myPawnModInfoDialog: false,
    myPawnModSellDialog: false,
    myPawnModBorrowDialog: false,

    changePriceDialog:false,

    // 领取模态框
    receiveSussess:false,

    userLoginDialog: userlogin,
    infoData: {},
    updating: '',
};

const modal = (state = defaultState, action) => {
    switch (action.type) { 
        case types.Modal_handle:
            return {
                ...state,
                ...action.info
            }
        default:
            return state
    }
};

export default modal;
