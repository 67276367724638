import Prod from './contract.prod.json'
import Test from './contract.test.json'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ETH: {
        test: Test.ETH_TEST,
        production: Prod.ETH_PROD,
    },
    BSC: {
        test: Test.BSC_TEST,
        production: Prod.BSC_PROD,
    },
    HECO: {
        test: Test.HECO_TEST,
        production: Prod.HECO_PROD,
    }
}
