import { useRef, useLayoutEffect } from "react"
import { Modal } from "antd"
import  './index.scss'
/**
 *
 * @param props.title {str}
 * @param props.button {div}
 * @param props.header {div}
 * @param props.topInput {div}
 * @param props.nopadding {div} content(props.children)
 * @param bodyClass {String} overwrite body style
 * @param headerClass {String} overwrite header style
 * @param autoScrollTop boolean always back to ViewModal Top
 * @param modalHeight set modal height
 * @param titleCenter set modal title center
 * @param titleClass set modal title class
 * @returns
 */
const ViewModal = (props) => {


    return <ViewModalCover
    {...props}
     >
        <ViewModalContent {...props} />
    </ViewModalCover>
}


const ViewModalCover = (props) => {
    return (
        <Modal
        width={500}
        {...props}
        bodyStyle={{padding:0,borderRadius: 0,backgroundColor:'transparent',}}
        title={null}
        footer={null}
        centered={true}
        closable={false}
        className={null}
        wrapClassName={null}
        >
            {
                props.children
            }
        </Modal>
    )
}

const ViewModalContent = (props) => {
    const scrollRef = useRef()
    useLayoutEffect(() => {
        if (props.autoScrollTop) {
            scrollRef?.current?.scrollTop > 0 && scrollRef.current.scrollTo({ top: 0 })
        }
    }, [props.visible])

    return (
        <div className={`view_modal ${props.bg ? props.bg : 'b_g_3'} br_4 flex column w100`} style={{maxHeight:props.modalHeight}}>
            {
                props.header?
                <div className={props.headerClass ? props.headerClass : "modal_tit pa_5"}>
                    {props.header}
                </div>
                :
                <div className="modal_tit flex justify_between align_center pa_5">
                    {
                        (props.titleCenter === undefined ?  true : props.titleCenter) &&
                        <div className="pa_3"></div>
                    }
                    <div className={`fw_600 modal-title ${(props.titleCenter === undefined ?  true : props.titleCenter) &&'w100 text_center t_es_1'} ${props.titleClass}`}>{props.title}</div>
                    <div onClick={props.onCancel} className='cursor'>
                        <i className="picon p-icon-close1 is_3"></i>
                    </div>
                </div>
            }
            {
                props.topInput&&
                <div className="modal_input">
                    {props.topInput}
                </div>
            }
            <div ref={scrollRef} className={props.bodyClass ? props.bodyClass : `modal_content px_5 ${props.nopadding&&'no_padding'} ${!props.button&&'mb_5'}` }>
                {props.children}
            </div>
            {
                props.button&&
                <div className="modal_btn pa_5">
                    {props.button}
                </div>
            }
        </div>
    )
}

ViewModal.Cover = ViewModalCover
ViewModal.Content = ViewModalContent


export default ViewModal
