/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionWalletModal,actionWalletLogin } from '@/store/actions/wallet';
import { useWeb3React } from '@web3-react/core';
import { injected, walletconnect, walletlinkconnect, bsc } from './connectors';
import { ChainConfig } from '@/config';
import { useEagerConnect, useInactiveListener } from './listener';
import {useTranslation} from 'react-i18next'
import { Modal } from 'antd';


import './wallet.scss';


const walletList = [
    {
        label: 'MetaMask',
        iconImage: '/images/wallet/metamask.png',
        instance: injected,
    },
    {
        label: 'MathWallet',
        iconImage: '/images/wallet/mathWallet.png',
        instance: injected,
    },
    {
        label: 'WalletConnet',
        iconImage: '/images/wallet/walletconnet.png',
        instance: walletconnect,
    },
    {
        label: 'Coinbase Wallet',
        iconImage: '/images/wallet/coinbaseWalletIcon.svg',
        instance: walletlinkconnect,
    },
    {
        label: 'Binance Chain Wallet',
        iconImage: '/images/wallet/binance.png',
        instance: bsc,
    }
]

const Wallet = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const storeData = useSelector((state) => state.wallet);
    const web3 = useWeb3React()
    const { active, account, chainId, connector, library, activate } = web3
    const [activatingConnector, setActivatingConnector] = useState();

    // 监听是否登录钱包
    const triedEager = useEagerConnect();
    useInactiveListener(!triedEager || !!activatingConnector);


    // 监听metamask变化，[ 检查状态，切链，切账户，退出(钱包锁定)，登录 ]
    useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
        updateWallet(active, account, chainId,1);
    }, [activatingConnector,connector,active, account, chainId]);

    // 监听metamask变化，[ 检查状态，切链，切账户，退出(非钱包锁定)， ]
    // useEffect(()=>{
    //     updateWallet(active, account, chainId,2);
    // },[active, account, chainId]);


    // 更新钱包登录状态
    const updateWallet = (active1, account1, chainId1,count)=>{

        // 账户状态未发生变化，退出
        if(account === storeData.account && chainId === storeData.chainId){
            return 0;
        }

        // 已登录，未拿到登录状态，退出
        const metamask = window.ethereum;
        if(!window.ethereum){
            return;
        }
        if(metamask.selectedAddress && !account){
            return;
        }

        const config = new ChainConfig(chainId);
        const chainInfo = config.currentChain

        actionWalletLogin({
            visible: false,
            active: active,
            account: account,
            chainId: chainId,
            env: chainInfo.env,
            networkName: chainInfo.networkName,
            chainType: chainInfo.type,
            network: chainInfo,
            library: library,
            chainInfo: config,
            isSupport: config.isSupportChain,
        })(dispatch)
    }


    // 打开链接操作
    const changeWallet = (c,label)=>{
        setActivatingConnector(c);
        activate(c);
    }


    return (
        <>
            <Modal visible={storeData.visible} maskClosable={false} centered onCancel={(e)=>{ actionWalletModal(false)(dispatch) }} className='walletSelect' title='Connect to a wallet' footer={null} width={458}>
                <div className="wallet-connect">
                    {
                        walletList.map(v=>{
                            return (
                                <div className="row" onClick={()=>{changeWallet(v.instance,v.label)}} key={v.label}>
                                    <span>{v.label}</span>
                                    <img src={v.iconImage} alt=""/>
                                </div>
                            )
                        })
                    }
                </div>
            </Modal>
            <Modal visible={storeData.active && !storeData.isSupport} closable={false} maskClosable={false} centered className='walletSelect' title="Wrong network" footer={null} width={458}>
                <div>Please change to Mainnet,Bscmain,Hecomain</div>
                {/* <div>ETH Test, BSC Test</div> */}
            </Modal>
        </>
    );
};

export default Wallet;



