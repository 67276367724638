import * as Tool from '@/utils/Tools';
import * as fmtDate from "@/utils/formatDate"
import { getClassifyType } from '@/service/contract/handleContract'

class handleDate {
    class = []
    params =  {}
    data = null
    init(data,params,classify){

        if(!data) return data;

        this.class = classify
        this.data = data
        this.params = params
        const newData = params._isprocess ? this.newFigures() : data;
        return newData
    }

    /**
     * 字段映射
     * @param {*} reqs
     * @param {*} classify
     */
    newFigures(){
        const dataType = this.getDataType()
        if(dataType === "array"){
            this.data.datas.map(val=>{
                return this.handle(val)
            })
        }
        else{
            this.data = this.handle(this.data)

        }
        return this.data;
    }
    handle(val){
        const type =  this.getType(val)
        val.onlyShowType=type[0]
        val.onlyShowPlatform=type[1]
        //judge buy borroow and join
        val.my_borrow = val.my_borrow ? val.my_borrow : {"f_order_index":""}
        val.my_buy = val.my_buy ? val.my_buy : {"f_order_index":""}
        val.my_join = val.my_join ? val.my_join : {"f_order_index":""}
        //zero-suppression 去除数据中的0
        let fliter = ["f_join_fee_rate","f_borrow_fee_rate","f_borrow_price","f_borrow_rate","f_clear_fee_rate","f_collateral_amount","f_collateral_amount_left","f_create_fee","f_loan_amount_rate","f_loan_rate","f_token_amount","f_token_amount_now","f_token_amount_remain","max_return","min_return"]
        for(let i in val){
            if(fliter.indexOf(i)>=0){
                val[i] = Tool.toFixed(val[i])
            }
            else{
                val[i] = val[i]
            }
        }
        //create time
        // val.creat_date = fmtDate.transferTime((val.block_number - val.f_create_block) * val.block_second * 1000,"M-d-y h:i P u")

        val.creat_date = fmtDate.getFormatDate(new Date(val.f_create_time*1000),"M-d-y h:i P u")
        //market surplus time
        val.surplus_time = Tool.divTime(val.block_number,val.f_wait_block,val.block_second)
        val.expire_time = Tool.divTime(val.block_number,val.f_sell_end_block,val.block_second)
        val.expire_rent_time = Tool.divTime(val.block_number,val.f_borrow_end_block,val.block_second)
        //截止时间
        val.expire_sale_data = fmtDate.transferTime((val.f_sell_end_block - val.block_number) * val.block_second * 1000,"M-d-y h:i P u")
        val.expire_rent_data = fmtDate.transferTime((val.f_borrow_end_block - val.block_number) * val.block_second * 1000,"M-d-y h:i P u")

        // get contract type [origin,erc20,erc721...]
        // console.log(this.class)
        val.collateral_contract_type = getClassifyType(this.class,val.f_collateral_type,val.f_collateral_from);
        val.ispawn = val.f_token_amount > 0 ? true : false
        val.issell = val.f_sell_price > 0 ?( val.f_sell_end_block > 0 ? true : false):false
        val.isborrow = val.f_borrow_price > 0 ? (val.f_borrow_end_block > 0 ? true : false):false
        return val
    }
    getType(obj){
        const arr = []
        this.class.forEach(val => {
            if(val.value===obj.f_collateral_type){
                arr.push(val.label)
                val.children.forEach(sub=>{
                    if(sub.value === obj.f_collateral_from){
                        arr.push(sub.label)
                    }
                })
                return 1
            }
        });
        return arr
    }
    //get data type
    getDataType(){
        if(!this.data.datas) return "object"
        if(this.data.datas instanceof Array){
            return "array"
        }
        else{
            return "object"
        }
    }
}


export default new handleDate()
