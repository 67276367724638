
import types from '../types'

const defaultState = {
    windowWidth:document.documentElement.clientWidth,
    windowHeight:document.documentElement.clientHeight
};

const rect = (state = defaultState, action) => {
    switch (action.type) {
        case types.setRect:
            return {
                ...state,
                ...action.info
            }
        default:
            return state
    }
};

export default rect;