import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actionModalHandle } from '@/store/actions/modal';

import './index.scss'

const LinkBox = (props)=>{

    const history = useHistory();
    const dispatch = useDispatch();
    const [state,setState] = useState(0);

    useEffect(()=>{
        // console.log(history.location.pathname)
        // console.log(state)
        props.listTab.forEach((val,i)=>{
        
            if(history.location.pathname.indexOf(val.link)>=0){
                setState(i);
                props.onChange && props.onChange(val);
            }
        })

        // const isLogin = window.common.storage('userAuthorize');
        // !isLogin && actionModalHandle({userLoginDialog: true})(dispatch);

    },[history.location.pathname])

    const changeLink = (v,i) =>{
        if(i === state){
            return 0;
        }
        // 更新state的值
        setState(i);
        v.link && history.push( v.link )
    }

    return (
        <div className="linkBox">
            {props.listTab.map((v,index)=>{
                return(
                    <div key={v.value} onClick={()=>changeLink(v,index)} className={state === index?'row on b_g_1 text_2':'row text_3'}>
                        <span>{v.label}</span>
                    </div>
                )
            })}
        </div>
    )

}


export default LinkBox;

