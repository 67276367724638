import Chain from './chain/chain.config';
import Interface from './interface/interface.config';
import Contract from './contract/contract.config';
import coinsToken from './contract/coin.config';
import classifyType from './common';

// default chainId
export const defaultChainId = window.ethereum
    ? (window.ethereum.selectedAddress ? (Number(window.ethereum.chainId)||1) : 1)
    : 1;
  
// available set of chain array
export const supportChainArray = [1,56,128]; //[1,3,56,97];
//4,97,256,3,测试网

export class ChainConfig {
    
    constructor(chainId){
        // default chainId
        this.defaultChainId = defaultChainId;
        // current chainId, if the current chainId does not exist, use the default chainId
        this.chainId = chainId || defaultChainId;
        this.isSupportChain = supportChainArray.includes(this.chainId);
        this.usableChainArray = this.getUsableChain('array');
        this.usableChainObject = this.getUsableChain('object');
        this.currentChain = this.getChainInfo(this.chainId);
        this.currentTradeCoin = this.getChainTradeCoin(this.chainId);
        this.apiAddress = this.getApiAddress(this.chainId);
        this.contractAddress = this.getContractAddress(this.chainId);
        this.usableUrl = this.getUsableUrl();
        this.classifyList = this.getClassify();
    }

    // 获取指定的合约地址（根据chainId，类型type）

    // 获取合约地址
    getContractAddress(chainId){
        const obj = this.currentChain;
        if(obj.type){
            return Contract[obj.type][obj.env];
        }
        return {}
    }

    // 获取接口地址
    getApiAddress(chainId){
        const obj = this.currentChain;
        // console.log(Interface[obj.env],">>>>>>>>>>>>>>>>")//https://bot.drep.org
        return Interface[obj.env];
    }

    // 获取指定的合约地址（根据chainId，类型type）
    getClassify(chainId){
        const obj = this.currentChain;
        if(obj.type){
            return classifyType[obj.type];
        }
        return [];
    }

    // 获取coin token
    getChainTradeCoin(chainId){
        const chainInfo = this.currentChain;
        if(chainInfo['type']){
            return coinsToken[chainInfo['type']][chainInfo['env']];
        }
        return {}
    }

    // 获取chain 对象
    getChainInfo(chainId){
        // const usable = this.usableChainArray;
        return Chain[chainId] ? Object.assign({}, Chain[chainId]) : {};
    }

    // 获取可用chain array对象
    getUsableChain(type='array'){
        const obj = type==='array' ? [] : {};
        supportChainArray.forEach((item)=>{
            if(Chain[item] !== undefined){
                if(type==='array'){
                    obj.push(Chain[item]);
                }else{
                    obj[item] = Chain[item];
                }
            }
        });
        return obj;
    }

    // 获取可用chain rpc url 对象
    getUsableUrl(){
        let url = {};
        Object.keys(this.usableChainObject).forEach(key=>{
            url[key] = this.usableChainObject[key]['rpcUrl'];
        })
        return url;
    }

}
