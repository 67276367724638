import { combineReducers } from 'redux';

import wallet from './wallet';
import pawn from './pawn';
import modal from './modal';
import rect from './rect'

const rootReducer = combineReducers({
    wallet,
    pawn,
    modal,
    rect,
})

export default rootReducer;
