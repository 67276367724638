
import { useSelector, useDispatch } from 'react-redux';
import { actionWalletModal,actionWalletLogout } from '@/store/actions/wallet';
import { Menu, Dropdown, message } from 'antd';
import { ButtonBox } from '@/components/ui'
import { LinkOutlined,ShareAltOutlined,DownOutlined } from '@ant-design/icons';

import './header.scss';
import { useState } from 'react';


const HeaderWallet = ()=>{
    const dispatch = useDispatch();
    const {account,active,network, chainInfo} = useSelector((state) => state.wallet);
    const [img,setImg] = useState(true)
 
    const logout = ()=>{
        actionWalletLogout({
            visible: false,
            active: false,
            account: '',
            // network: '',
            // chainId: ''
        })(dispatch)
    }

    const copyAddrFunc = ()=>{
        window.navigator.clipboard.writeText(account).then(() => {
            message.success('success');
        },(err) => {
            console.log('fail')
        });
    }

    const menu = 
        <div className='dropdown_div'>
            <div className="dropdown_div_first">
                {/* <img src="/images/credit/ic_wallet.png" alt="" style={{'width':'20px'}}/> */}
                <i className="picon text_1 p-icon-wallet is_2"></i>
                <span className="name">Invite program</span>
                <span className="coming_soon">Coming soon</span>
            </div>
            <div onClick={(e)=>copyAddrFunc()} className="dropdown_div_first">
                {/* <LinkOutlined />&nbsp;&nbsp;Copy address */}
                {/* <img src="/images/credit/ic_copy.png" alt="" style={{'width':'20px'}}/> */}
                <i className="picon text_1 p-icon-copy is_2"></i>
                <span className="name">Copy address</span>
            </div>
            <div  className="dropdown_div_first" onClick={() => {window.open(`${chainInfo.currentChain.explorerUrl}address/${account}`)}}>
                {/* <a href={`${chainInfo.currentChain.explorerUrl}address/${account}`} target="_blank" rel="noreferrer"> */}
                    {/* <ShareAltOutlined /> */}
                    {/* &nbsp;&nbsp;{t('header.toWebsite',{ p: chainInfo.currentChain.explorerName })} */}
                    {/* &nbsp;&nbsp;{`Open in ${chainInfo.currentChain.explorerName}` }
                </a> */}
                {/* <img src="/images/credit/ic_link.png" alt="" style={{'width':'20px'}}/> */}
                <i className="picon text_1 p-icon-share is_2"></i>
                <span className="name">{`Open in ${chainInfo.currentChain.explorerName}` }</span>
            </div>
            <div className="dropdown_btn bd text_3" onClick={(e)=>{ logout() }}>
                Log Out
                {/* <div style={{'marginLeft':'16px','marginRight':'16px'}}>
                    <ButtonBox img='img_wallet_white.png' click={(e)=>{ logout() }} size='small' fill >Log Out</ButtonBox>
                </div> */}
            </div>
        </div>

    return (
            <>
                {
                    active ?
                    <div className="headerWallet" 
                    onMouseOver={()=>setImg(false)} onMouseLeave={()=>setImg(true)}>
                        <Dropdown overlay={menu} className="connectWallet" >
                            <div className="ant-dropdown-link flex align_center fs_2" onClick={e => e.preventDefault()} >

                                {/* <img src="/images/credit/ic_wallet.png" alt="" style={{'width':'20px'}}/> */}
                                <div className="flex align_center">
                                    <i className="picon text_1 p-icon-wallet is_2"></i>
                                    <div className="addr">
                                        { account.substring(0, 6) + "…" + account.substring(account.length, account.length - 4) }
                                    </div>

                                    <div className={network.networkName ? 'netType success' : 'netType error'}>
                                        { network.networkName || "Wrong network" }
                                    </div>
                                </div>
                                
                                <img src={img?"/images/credit/ic_downfill.png":"/images/credit/ic_downfill.png"} alt=""/>
                                {/* <DownOutlined /> */}
                            </div>
                        </Dropdown>
                    </div>
                    : <div className="b_g_1 pa_4 text_2 br_4 cursor" showImg={false}  onClick={(e)=>{ actionWalletModal(true)(dispatch) }}>Connect to a wallet</div>
                }
            </>
    )
}
export default HeaderWallet;


