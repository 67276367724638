

function fillZore(v){
    return v >= 10 ? v+'' : '0' + v;
}

function delRepeat(arr){

    if(arr.length<2){
        return arr;
    }

    let narr = [];
    let record;
    arr.forEach((v,i)=>{
        if(v===record){
            narr[narr.length-1] = narr[narr.length-1]+v.toString();
        }else{
            record = v;
            narr.push(v);
        }
    })
    return narr;
}

function toEnDate(index){
    const list = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ]
    return list[index-1];
}

function transferInfo(type,date){

    switch(type){
        case 'yy':
            return date[0].substring(2,4);
        case 'y':
            return date[0];
        case 'm':
            return date[1];
        case 'M':
            return toEnDate(date[1]);
        case 'd':
            return date[2];
        case 'H':
            return date[3];
        case 'h':
            return date[3] > 12 ? date[3] - 12 : date[3];
        case 'I':
            return date[4];
        case 'i':
            return date[4];
        case 'S':
            return date[5];
        case 's':
            return date[5];
        case 'P':
            return date[3] > 12 ?  "PM" : "AM";
        case 'p':
            return date[3] > 12 ?  "pm" : "am";
        case 'u':
            const utc = new Date().getTimezoneOffset()/60;
            return "UTC" + (utc>=0?"-":"+") + Math.abs(utc);
        case ' ':
            return " ";
        case '/':
            return "/";
        case '*':
            return "*";
        case '#':
            return "#";
        case '%':
            return "%";
        case '|':
            return "|";
        case '-':
            return "-";
        case ':':
            return ":";
        default:
            return ''
    }
}

function dateInfo(date){
    let y = fillZore(date.getFullYear());
    let m = fillZore(date.getMonth() + 1);
    let d = fillZore(date.getDate());
    let h = fillZore(date.getHours());
    let i = fillZore(date.getMinutes());
    let s = fillZore(date.getSeconds());
    return [y,m,d,h,i,s];
}

// Returns the specified date format
export function getFormatDate(date,formatType){

    if(!formatType) return date;

    const typeArray = delRepeat(formatType.split(''));
    const dateInfoArray = dateInfo(date)


    let joinDate = [];
    typeArray.forEach(type=>{
        joinDate.push( transferInfo(type,dateInfoArray) );
    })

    return joinDate.join('');

}


export function transferTime(timeStamp,type){

    let toStamp = Date.parse(new Date());
    toStamp += timeStamp;
    const date = new Date(toStamp);
    return getFormatDate(date,type)

}


export function changeTime(timeStamp,type){
    const date = new Date(timeStamp);

    return getFormatDate(date,type)
}

