/* eslint-disable import/no-anonymous-default-export */
import Web3 from 'web3';

import erc20 from '@/config/abi/ERC20';
import erc721 from '@/config/abi/ERC721';
import erc1155 from '@/config/abi/ERC1155';
import SignToken from '@/config/abi/SignToken';

export default {

    connecter: null,

    init(library){
        this.connecter = library && library.provider ? new Web3(library.provider) : null;
        return this.connecter;
    },

    /**
     * get the name of token, support [lptokens erc20 erc721 origin]
     * @param {library} info  wallet
     * @param {contractAddr,poolTypeAddr,contractType,id} params collect
     */
    async getName(info,params) {
        this.init(info.library);
        if(!this.connecter) return "-1";

        try {
            const instance = new this.connecter.eth.Contract(erc20, params.contractAddr);
            const name = await instance.methods.name().call();
            console.log('getName: ' + name);
            return name;
        } catch (error) {
            console.error('getName: ', error);
            return '';
        }
    },

    /**
     * returned token balance, support [lptokens erc20 erc721 erc1155 origin]
     * @param {library} info  wallet
     * @param {contractAddr,poolTypeAddr,contractType,id} params collect
     */
    async getBalanceOf(info,params) {
        this.init(info.library);
        if(!this.connecter) return "-1";

        try {
            console.log(params);
            let instance,balanceOf;
            switch(params.contractType){
                case 'lptokens':
                case 'erc20':
                case 'erc721':
                    instance = new this.connecter.eth.Contract(erc20, params.contractAddr );
                    balanceOf = await instance.methods.balanceOf(info.account).call();
                    break;
                case 'erc1155':
                    instance = new this.connecter.eth.Contract(erc1155, params.contractAddr );
                    balanceOf = await instance.methods.balanceOf(info.account,params.id).call();
                    break;
                case 'origin':
                    balanceOf = await this.connecter.eth.getBalance(info.account);
                    break;
                default:
                    break;
            }
            console.log('getBalanceOf: ' + balanceOf);
            return balanceOf;
        } catch (error) {
            console.error('getBalanceOf: ', error);
            return -1;
        }
    },

    /**
     * returned token symbol, support [lptokens erc20 erc721 erc1155 origin]
     * @param {library} info  wallet
     * @param {contractAddr,poolTypeAddr,contractType,id} params collect
     */
    async getSymbol(info,params) {
        this.init(info.library);
        if(!this.connecter) return "-1";

        try {
            const instance = new this.connecter.eth.Contract(erc20, params.contractAddr ); // token contract
            const symbol = await instance.methods.symbol().call();
            console.log('symbol: ' + symbol);
            return symbol ? symbol : '';
        } catch (error) {
            console.error('symbol: ', error);
            return '';
        }
    },

    // returned token ID owner || erc721
    async getOwnerOf(info,params) {
        this.init(info.library);
        if(!this.connecter) return "-1";

        try {
            const instance = new this.connecter.eth.Contract(erc721, params.contractAddr);
            let ownerOf = await instance.methods.ownerOf(params.tokenId).call();
            console.log('getOwnerOf: ' + ownerOf);
            return ownerOf;
        }catch(error){
            console.error('getOwnerOf: The NFTs ID error or non-existent');
            return -1;
        }
    },

    // returned token Decimals || erc20
    async getDecimals(info,params) {
        this.init(info.library);
        if(!this.connecter) return "-1";

        try {
            const instance = new this.connecter.eth.Contract(erc20, params.contractAddr);
            const decimals = await instance.methods.decimals().call();
            console.log('decimals',decimals)
            return decimals;
        } catch(error) {
            console.error('decimals',error);
            return 0;
        }
    },

    // returned token uri || erc721
    async getTokenURI(info,params) {
        this.init(info.library);
        if(!this.connecter) return "-1";

        try {
            const instance = new this.connecter.eth.Contract(erc721, params.contractAddr);
            const tokenuri = await instance.methods.tokenURI(params.tokenId).call();
            console.log('getTokenURI: ' + tokenuri);
            return tokenuri;
        } catch (error) {
            console.error('getTokenURI: The NFTs ID error or non-existent');
            return '';
        }
    },

    /**
     * access to authorization, support [lptokens erc20 erc721 erc1155 origin]
     * @param {library} info  wallet
     * @param {contractAddr,poolTypeAddr,contractType,id} params collect
     */
    async getAllowance(info,params) {
        this.init(info.library);
        if(!this.connecter) return "-1";

        try{
            let instance,allowanceAmount;
            switch(params.contractType){
                case 'erc20':
                case 'lptokens':
                    instance = new this.connecter.eth.Contract(erc20, params.contractAddr );
                    allowanceAmount = await instance.methods.allowance(info.account,params.poolAddr).call();
                    break;
                case 'erc721':
                    instance = new this.connecter.eth.Contract(erc721, params.contractAddr );
                    allowanceAmount = await instance.methods.isApprovedForAll(info.account, params.poolAddr).call();
                    break;
                case 'erc1155':
                    instance = new this.connecter.eth.Contract(erc1155, params.contractAddr );
                    allowanceAmount = await instance.methods.isApprovedForAll(info.account, params.poolAddr).call();
                    break;
                default:
                    break;
            }
            console.log('allowanceAmount: ' + allowanceAmount);
            return allowanceAmount;
        } catch (error){
            console.error('allowanceAmount: ', error);
            return -1;
        }
    },

    /**
     * Account authorization , support [lptokens erc20 erc721 erc1155]
     * @param {library} info  wallet
     * @param {contractAddr,poolTypeAddr} params collect
     */
    approve(info, params, amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff') {
        this.init(info.library);
        if(!this.connecter) return "-1";

        return new Promise((resolve,reject)=>{
            console.log(params);
            try {

                let instance,approve;
                switch(params.contractType){
                    case 'erc20':
                    case 'lptokens':
                        instance = new this.connecter.eth.Contract(erc20, params.contractAddr );
                        approve = instance.methods.approve(params.poolAddr, amount);
                        break;
                    case 'erc721':
                    case 'erc1155':
                        instance = new this.connecter.eth.Contract(erc1155, params.contractAddr );
                        approve = instance.methods.setApprovalForAll(params.poolAddr, true);
                        break;
                    default:
                        break;
                }

                approve
                    .send({ from: info.account })
                    .on('error', function (error) {
                        console.log('invoke approve error: ',error);
                        resolve({code:0});
                    })
                    .on('transactionHash', function (transactionHash) {
                        console.log('invoke approve pending: ' + transactionHash);
                    })
                    .on('receipt', (receipt) => {
                        console.log('approve receipt',receipt);
                        resolve({code:99999});
                    });
            } catch (error) {
                console.error('approve: ', error);
                resolve({code:0});
            }
        })
    },








    /**
     * 领取奖励
     * @param {library} info  wallet
     * @param {contractAddr,toAddress,tokenAddress[],tokenAmount[],deadline,v,r,s} params collect
     */
     claimBySig(info,params){
        this.init(info.library);
        if(!this.connecter) return "-1";

        return new Promise((resolve,reject)=>{
            let hash
            try {
                console.log(params);
                
                const instance = new this.connecter.eth.Contract(SignToken, params.contractAddr);
                return instance.methods.claimBySig(params.userId,params.toAddress,params.tokenAddress,params.tokenAmount,params.deadline,params.v,params.r,params.s)
                    .send({ from: info.account })
                    .on('error', function (error) {
                        console.log(error);
                        resolve({code:0,hash});
                    })
                    .on('transactionHash', function (transactionHash) {
                        hash = transactionHash
                        console.log('claimBySig pending: ' + transactionHash);
                        resolve({code:66666,hash})
                    })
                    .on('receipt', (receipt) => {
                        console.log('claimBySig receipt');
                        resolve({code:99999,hash});
                    });
            } catch (error) {
                console.error('claimBySig:', error);
                resolve({code:0,hash});
            }
        })
    },

}