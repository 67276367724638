import { query } from './request';


// 项目列表
export function ApiProjectInfo(params){ 
    return query('/api/withdraw/queryprojectinfo.do',params);
}

//项目详情
export function ApiProjectDetail(params){
    return query(`/api/withdraw/queryprojectdetail.do`,params)
}

//查询用户余额
export function ApiUserBalance(params){
    //?p={"useraddr":"0x1F6e46dB43838FC81aAf6A93a446D67eB430a385"}
    return query(`/api/withdraw/queryuserbalance.do`,params)
}

//获取签名
export function ApiwithDrawsign(params){
    return query(`/api/withdraw/withdrawsign.do`,params)
}

//点赞
export function ApiGetLike(params){
    return query(`/api/withdraw/commentproject.do`,params)
}

// 积分统计
export function ApiGetpoint(params){
    return query(`/api/withdraw/querysigninpointstats.do`, params)
}

//积分列表
export function ApiGetpointList(params){
    return query(`/api/withdraw/querysigninpointrank.do`, params)
}


