
import CommonContract from '@/service/contract'
import * as Tool from '@/utils/Tools';

// get token name
export async function getName(wallet,data){
    const opts = {
        contractAddr: data.contractAddr
    }
    const name = await CommonContract.getName(wallet,opts);
    return name;
}

// get token symbol
export async function getSymbol(wallet,data){
    const opts = {
        contractAddr: data.contractAddr
    }
    const symbol = await CommonContract.getSymbol(wallet,opts);
    return symbol;
}

// get token url
export async function getTokenURI(wallet,data){
    const opts = {
        contractAddr: data.contractAddr,
        tokenId: data.tokenId,
    }
    const uri = await CommonContract.getTokenURI(wallet,opts);
    return uri;
}

// get NFTs Id
export async function getNftsTokenId(wallet,data){
    const opts = {
        contractAddr: data.contractAddr,
        tokenId: data.tokenId,
    }
    const owner = await CommonContract.getOwnerOf(wallet,opts);
    if(wallet.account.toString()===owner.toString()){
        return true;
    }
    return false;
}

// get Balance
export async function getMultiTypeBalance(wallet,data){

    const contractType = data.contractType || getTradeCoin(data.contractAddr,wallet.chainInfo).contract_type;
    const info = {
        contractAddr: data.contractAddr,
        poolAddr: data.poolAddr || wallet.chainInfo.contractAddress.OrderTrade,
        contractType: contractType,
    }

    let balance,decimals=18;

    switch(contractType){
        case 'lptoken':
        case 'lptokens':
        case 'erc20':
            balance =  await CommonContract.getBalanceOf(wallet,info);
            if(balance !== -1){
                decimals = await CommonContract.getDecimals(wallet,info);
                balance = Tool.fromWei(balance,decimals);
            }
            break;
        case 'erc721':
            decimals = 0;
            balance =  await CommonContract.getBalanceOf(wallet,info);
            break;
        default:
            balance =  await CommonContract.getBalance(wallet);
            balance = Tool.fromWei(balance);
    }

    return data.isDecimal ? { balance, decimals } : balance;
}

// get approve
export async function queryApproveBalance(wallet,data){


    const contractType = data.contractType || getTradeCoin(data.contractAddr,wallet.chainInfo).contract_type;
    if(contractType==='origin') return "999999999999999999999999";

    const info = {
        contractAddr: data.contractAddr,
        poolAddr: data.poolAddr || wallet.chainInfo.contractAddress.OrderTrade,
        contractType: contractType
    }

    let approveBalance =  await CommonContract.getAllowance(wallet,info);
    return approveBalance;
}

// user authorize
export async function userAuthorizeHandle(wallet,data){

    const contractType = data.contractType || getTradeCoin(data.contractAddr,wallet.chainInfo).contract_type;
    const info = {
        contractAddr: data.contractAddr,
        poolAddr: data.poolAddr || wallet.chainInfo.contractAddress.OrderTrade,
        contractType: contractType,
    }

    let result = await CommonContract.approve(wallet,info);
    return result;
}







// tokenname by config coin
const getTradeCoin = (contractAddr,chainInfo)=>{
    if(contractAddr.length<1) return '';

    let tokenName = '';
    let coinObj = {};
    let configAddr = chainInfo.contractAddress;
    let configCoin = chainInfo.currentTradeCoin;
    for(let addr in configAddr){
        if(configAddr[addr].toUpperCase()===contractAddr.toUpperCase()){
            tokenName = addr;
            break;
        }
    }

    configCoin.forEach(v=>{
        if(v.value.toUpperCase()===tokenName.toUpperCase()){
            coinObj = v;
        }
    })

    return coinObj;
}

// return contract type
export function getClassifyType(list,collateralType,collateralFrom){
    let str = '';
    for(let k = 0; k<list.length; k++){
        const info = list[k];
        if(info.value === collateralType){
            for(let j=0; j<info.children.length;j++){
                const sub = info.children[j];
                if(sub.value===collateralFrom){
                    str = sub.contract_type;
                    return str;
                }
            }
        }
    }
}


