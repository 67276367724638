import { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import './header.scss';

const Mainnet = ()=>{
    const { wallet } = useSelector(state=>state);
    const [coinList,setCoinList] = useState([]);
    const [coinDefault,setCoinDefault] = useState({});

    useEffect(()=>{

        if(!wallet.chainType) return;
        setCoinDefault(wallet.chainInfo.currentChain || {});
        setCoinList(wallet.chainInfo.usableChainArray || [])

    },[wallet])

    return (
        <div className="mainnet l-hide m-hide">
            <Dropdown overlay={<MenuInfo list={coinList} />}>
                <img src={`/images/icon/icon_${coinDefault.type ? coinDefault.type.toLowerCase(): 'eth'}.png`} alt="" />
            </Dropdown>
        </div>
    )
}

const MenuInfo = (props)=>{

    return (
        <>
        <Menu className="mainnetType">
            {
                props.list.map(val=>
                    <Menu.Item className="mainnetType" key={val.rpcUrl}>
                        <img src={`/images/icon/icon_${val.type.toLowerCase()}.png`} alt="" style={{width:18}}/>&nbsp;&nbsp;<span>{val.networkName}</span>
                    </Menu.Item>
                )
            }
        </Menu>
        </>
    )
}


export default Mainnet;

