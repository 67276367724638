import React, { useState,useEffect } from 'react';
import { Link, useLocation,useHistory } from 'react-router-dom'
import './header.scss';
import HeaderNav from './nav'
// import HeaderLang from './language'
import HeaderWallet from './walletConnect'
import Mainnet from './mainnet'
import BotPoint from './botPoint';
// import { Dropdown, Menu, Button } from 'antd'

const CommonHeader = () => {
    const [show, setShow] = useState(false)
    const [menuShow,setMenuShow] = useState(true)
    const location = useLocation();
    const history = useHistory()

    const toIndex = ()=>{
        history.push('/')
    }
    return (
        <>
        <div className="header">
            <div
                className={ menuShow ? "dropMenu m-hide l-hide" : "dropMenu lose m-hide l-hide"}
                style={{backgroundImage:"url(/images/credit/ic_hamburg.png)"}}
                onClick={() =>{
                    setShow(show => !show)
                    // 点击按钮,让按钮消失
                    setMenuShow(false)
                }}>
            </div>
            <div className='containers px_5'>
                <div className="logo">
                    {/* <img src="/images/credit/logo_drepcredit_blue_h.png" alt="" onClick={toIndex} /> */}
                    <svg className="icon mr_2" aria-hidden="true"  style={{width:'140px'}}>
                        <use xlinkHref={`#p-icon-logo`}></use>
                    </svg>
                </div>
                <div className="s-hide">
                    <HeaderNav />
                </div>
                <div className='nav-right s-hide'>
                    <div className="select">
                        {/* <HeaderLang /> */}
                        <BotPoint />
                        <HeaderWallet />
                        <Mainnet />
                    </div>
                </div>
            </div>
        </div>
          {/* 滑动弹窗 */}
          <nav className={!show ? "dropmenuList hide" : "dropmenuList background display_block"} >
             
          <div className={show ? "content " : "content hide2"} onTouchMove={()=>{
            setShow(false)
            setMenuShow(true)
        }}>
              <div className='nav-right' >
              <div className="select">
                      {/* <HeaderLang /> */}
                      <HeaderWallet />
                      {/* <Mainnet /> */}
                  </div>
                  {/* 为子组件传递点击事件的方法,然后让点击事件在父组件触发效果 */}
                  <HeaderNav handleClick={()=>{
                      setShow(false)
                      setMenuShow(true)
                  }}/>
                
              </div>
          </div>
          <div 
          className={!show ? "slide hide" : "slide background"}
          onClick={()=>{
            setShow(false)
            setMenuShow(true)
        }}>

          </div>
      </nav>
      </>
    )

}


export default CommonHeader;

