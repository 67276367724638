/* eslint-disable no-extend-native */


/*
 * @describe remove left and right empty strings
 * @param String [str]
 * return String
 */
function trimSpace(){
    let str = this + '';
    console.log(str);
    return str.replace(/\s+/g,'');
}
Number.prototype.trimSpace =trimSpace
String.prototype.trimSpace =trimSpace

/*
 * @describe Shorten string length
 * @param Int [beforeLen]
 * @param Int [afterLen]
 * @param String [adorn]
 * return String
 */
function reduceLen(beforeLen=6,afterLen=4,adorn="..."){

    let value = this.toString();

    if(!value || value.length < beforeLen + afterLen){
        return value.toString();
        //throw new Error('methods reduceLen:',"Value Invalid, Length not enough ");
    }

    return value.substring(0,beforeLen) + adorn + value.substring(value.length,value.length - afterLen)
}
Number.prototype.reduceLen = reduceLen
String.prototype.reduceLen = reduceLen
