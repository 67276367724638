import { InjectedConnector } from '@web3-react/injected-connector'
import { NetworkConnector } from '@web3-react/network-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { BscConnector } from '@binance-chain/bsc-connector'

import { defaultChainId, supportChainArray, ChainConfig } from '@/config'
const { currentChain, usableUrl } = new ChainConfig();


// metamask mathwallet
export const isInjectedSupported = () => {
    //  return chainConfig.supportChainArray.length > 0;
    return true;
}

export const isNetworkConnectorSupported = () => {
    return true;
}

export const isWalletconnectSupported = () => {
    return true;
}

export const isWalletlinkSupported = () => {
    return true;
}

export const isBscSupported = () => {
    return supportChainArray.filter((item) => item === 56 || item === 97).length > 0;
}

export const injected = new InjectedConnector({
    supportChainArray
});

export const network = new NetworkConnector({
    urls: usableUrl,
    defaultChainId,
})

export const walletconnect = new WalletConnectConnector({
    rpc: { [defaultChainId]: [currentChain.rpcUrl] },
    bridge: 'https://bridge.walletconnect.org',
    qrcode: true,
    pollingInterval: 15000,
});

export const walletlinkconnect = new WalletLinkConnector({
    appName: window.location.origin,
    url: currentChain.rpcUrl,
})

export const bsc = new BscConnector({
    supportChainArray: supportChainArray.filter((item) => item === 56 || item === 97)
})
